<!--综合测评选项-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">综合测评</div>
    <!--=====================================工具栏========================================-->
    <div class="btngroup">
      <el-button type="primary" size="mini" @click="getDataPageList"
        >查询</el-button
      >
      <el-button type="primary" size="mini" @click="showcxsp"
        >撤销审批</el-button
      >
      <el-button type="primary" size="mini" @click="showdetail">查看</el-button>
    </div>
    <div class="sm_search">
      <div class="sm_search_group">
        <div class="sm_search_item">
          <div
            v-html="'学&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号'"
            class="sm_search_title"
          ></div>
          <el-input size="mini" v-model="xh"></el-input>
        </div>
        <div class="sm_search_item">
          <div
            v-html="'姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名'"
            class="sm_search_title"
          ></div>
          <el-input size="mini" v-model="xm"></el-input>
        </div>
        <div class="sm_search_item">
          <div
            v-html="'学&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;期'"
            class="sm_search_title"
          ></div>
          <el-select v-model="xq" size="small" clearable style="width: 178px">
            <el-option
              v-for="(item, index) in xqlist"
              :key="index"
              :label="item.ksnf + '-' + item.jsnf + '第' + item.xq + '学期'"
              :value="item.ksnf + '-' + item.jsnf + '第' + item.xq + '学期'"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="sm_search_group">
        <div class="sm_search_item">
          <div class="sm_search_title">二级学院</div>
          <el-select
            v-model="bmbh"
            style="display: block; width: 178px"
            size="mini"
            @change="bmchange"
            clearable
          >
            <el-option
              v-for="(item, index) in bmlist"
              :key="index"
              :label="item.bmmc"
              :value="item.bmbh"
            ></el-option>
          </el-select>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">专业名称</div>
          <el-select
            v-model="zybh"
            style="display: block; width: 178px"
            size="mini"
            @change="zychange"
            clearable
          >
            <el-option
              v-for="(item, index) in zylist"
              :key="index"
              :label="item.zymc"
              :value="item.zybh"
            ></el-option>
          </el-select>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">班级名称</div>
          <el-select
            v-model="bjbh"
            style="display: block; width: 178px"
            size="mini"
            clearable
          >
            <el-option
              v-for="(item, index) in bjlist"
              :key="index"
              :label="item.bjmc"
              :value="item.bjbh"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="sm_search_group">
        <div class="sm_search_item">
          <div
            class="sm_search_title"
            v-html="'类&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别'"
          ></div>
          <el-select v-model="lb" size="small" clearable style="width: 178px">
            <el-option label="德育素质" :value="1"></el-option>
            <el-option label="智育素质" :value="2"></el-option>
            <el-option label="体育素质" :value="3"></el-option>
            <el-option label="美育素质" :value="4"></el-option>
            <el-option label="劳动素质" :value="5"></el-option>
            <el-option label="能力素质" :value="6"></el-option>
          </el-select>
        </div>
        <div class="sm_search_item">
          <div
            class="sm_search_title"
            v-html="'类&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;型'"
          ></div>
          <el-select v-model="lx" size="small" clearable style="width: 178px">
            <el-option label="加分" :value="1"></el-option>
            <el-option label="减分" :value="2"></el-option>
          </el-select>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">审批状态</div>
          <el-select v-model="spzt" size="small" clearable style="width: 178px">
            <el-option label="待审批" :value="0"></el-option>
            <el-option label="审批通过" :value="1"></el-option>
            <el-option label="审批不通过" :value="2"></el-option>
            <el-option label="审批撤销" :value="3"></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        stripe
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="xh" label="学号" width="150"> </el-table-column>
        <el-table-column prop="xm" label="姓名" width="100"> </el-table-column>
        <el-table-column prop="lb" label="类别" width="100">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.lb === 1">德育素质</el-tag>
            <el-tag v-if="scope.row.lb === 2">智育素质</el-tag>
            <el-tag v-if="scope.row.lb === 3">体育素质</el-tag>
            <el-tag v-if="scope.row.lb === 4">美育素质</el-tag>
            <el-tag v-if="scope.row.lb === 5">劳动素质</el-tag>
            <el-tag v-if="scope.row.lb === 6">能力素质</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="lx" label="类型" width="100">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.lx === 1">加分</el-tag>
            <el-tag type="danger" v-if="scope.row.lx === 2">减分</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="xq" label="学期" width="200"> </el-table-column>
        <el-table-column prop="fs" label="分数" width="100"> </el-table-column>
        <el-table-column prop="spzt" label="审批状态" width="100">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.spzt === 0">待审批</el-tag>
            <el-tag type="success" v-if="scope.row.spzt === 1">审批通过</el-tag>
            <el-tag type="danger" v-if="scope.row.spzt === 2"
              >审批不通过</el-tag
            >
            <el-tag type="warning" v-if="scope.row.spzt === 3">审批撤回</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="jfsm" label="说明"> </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      width="30%"
      title="撤销审批"
      :visible.sync="cxyyVisible"
      append-to-body
    >
      <el-form>
        <el-form-item label="撤销原因">
          <el-input
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 5 }"
            v-model="cxyy"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cxyyVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveCX">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog width="50%" title="详情" :visible.sync="detailVisible">
      <el-form>
        <el-form-item label="评分内容及标准" label-width="150px">
          <el-input
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 5 }"
            readonly
            v-model="innerforminfo.PFNR"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="学期" label-width="150px" prop="XQ">
          <el-input readonly v-model="innerforminfo.XQ"> </el-input>
        </el-form-item>
        <el-form-item label="加分说明" label-width="150px" prop="JFSM">
          <el-input
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 5 }"
            v-model="innerforminfo.JFSM"
            readonly
          >
          </el-input>
        </el-form-item>
        <el-form-item label="分数" label-width="150px" prop="FS">
          <el-input-number
            v-model="innerforminfo.FS"
            :precision="1"
            :step="0.1"
            :max="100"
            :min="0"
            readonly
          ></el-input-number>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="附件" label-width="150px">
              <div class="demo-image__preview">
                <el-image
                  v-for="item in innerforminfo.fj"
                  :key="item.id"
                  style="width: 100px; height: 100px; margin-right: 10px"
                  :src="baseUrl + item.filePath"
                  :preview-src-list="new Array(baseUrl + item.filePath)"
                  fit="cover"
                >
                </el-image>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="当前状态" label-width="150px">
              <el-tag v-if="innerforminfo.SPZT === 0">待审批</el-tag>
              <el-tag type="success" v-if="innerforminfo.SPZT === 1"
                >审批通过</el-tag
              >
              <el-tag type="danger" v-if="innerforminfo.SPZT === 2"
                >审批不通过</el-tag
              >
              <el-tag type="warning" v-if="innerforminfo.SPZT === 3"
                >审批撤回</el-tag
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item
          v-if="innerforminfo.SPZT == 3"
          label="撤销原因"
          label-width="150px"
          prop="CXYY"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 5 }"
            v-model="innerforminfo.CXYY"
            readonly
          >
          </el-input>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { baseUrl } from '../../utils/http'
import {
  GetJFGLPageList,
  GetZHCPFormData,
  saveCX,
  exportExcel,
} from '../../api/zhcp'
import { GetListByQX, GetZYListByQX, GetBJListByQX } from '../../api/jcxx'
import { getAuthorizeButtonColumnList, getXQList } from '../../api/system'
import moment from 'moment'
export default {
  data() {
    return {
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      baseUrl: baseUrl,
      windowHeight: document.documentElement.clientHeight - 150, // 实时屏幕高度
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      xh: '',
      xm: '',
      lx: 1,
      lb: '',
      xq: '',
      bmlist: [],
      zylist: [],
      bjlist: [], // 班级列表
      bmbh: '',
      zybh: '',
      bjbh: '',
      xqlist: [],
      forminfo: {
        ID: '',
        XID: '',
        JFSM: '',
        FS: 0,
        FJIDS: [],
      },
      PFNR: '',
      spzt: null,
      cxyy: '',
      cxyyVisible: false,
      detailVisible: false,
      innerforminfo: {
        ID: '',
        XID: '',
        JFSM: '',
        FS: 0,
        CXYY: '',
        SPZT: 0,
        FJIDS: [],
      },
    }
  },
  created() {
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
    getXQList().then((res) => {
      if (res.code === 200) {
        this.xqlist = res.data
      }
    })
    GetListByQX().then((res) => {
      if (res.code === 200) {
        this.bmlist = res.data
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.forminfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      GetJFGLPageList({
        queryJson: JSON.stringify({
          lb: this.lb,
          lx: this.lx,
          xq: this.xq,
          bmbh: this.bmbh,
          zybh: this.zybh,
          bjbh: this.bjbh,
          spzt: this.spzt,
          keyword: this.keyword,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY年MM月DD日 HH:mm')
    },
    // 时间格式化
    dateFormat3(value) {
      if (!value) {
        return ''
      }

      return moment(value).format('YYYY-MM-DD')
    },
    // 获取专业列表
    getZY() {
      GetZYListByQX({ BMBH: this.bmbh }).then((res) => {
        if (res.code === 200) {
          this.zylist = res.data
        }
      })
    },
    // 获取班级列表
    getBJ() {
      GetBJListByQX({ ZYBH: this.zybh }).then((res) => {
        if (res.code === 200) {
          this.bjlist = res.data
        }
      })
    },
    bmchange() {
      this.zybh = ''
      this.bjbh = ''
      this.getZY()
    },
    zychange() {
      this.bjbh = ''
      this.getBJ()
    },
    showcxsp() {
      GetZHCPFormData({ id: this.keyValue }).then((res) => {
        if (res.code === 200) {
          if (res.data.result.spzt === 1) {
            this.cxyyVisible = true
          } else {
            this.$message.error('该状态不能撤销！')
          }
        }
      })
    },
    saveCX() {
      saveCX({ SQID: this.keyValue, CXYY: this.cxyy }).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message)
          this.cxyyVisible = false
          this.getDataPageList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    showdetail() {
      GetZHCPFormData({ id: this.keyValue }).then((res) => {
        if (res.code === 200) {
          this.detailVisible = true
          this.innerforminfo.PFNR = res.data.result.pfnr
          this.innerforminfo.XQ = res.data.result.xq
          this.innerforminfo.JFSM = res.data.result.jfsm
          this.innerforminfo.FS = res.data.result.fs
          this.innerforminfo.CXYY = res.data.result.cxyy
          this.innerforminfo.SPZT = res.data.result.spzt
          this.innerforminfo.fj = res.data.fj
        }
      })
    },
    exportToExcel() {
      this.$message.info('数据导出中，请耐心等待！')
      this.dialogExcelVisible = false
      exportExcel({
        queryJson: JSON.stringify({
          lb: this.lb,
          lx: this.lx,
          xq: this.xq,
          bmbh: this.bmbh,
          zybh: this.zybh,
          bjbh: this.bjbh,
          spzt: this.spzt,
          keyword: this.keyword,
        }),
        fileName: '综合素质测评',
      }).then((res) => {
        console.log(res)
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8',
        })
        // 针对于IE浏览器的处理, 因部分IE浏览器不支持createObjectURL
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, res.fileName)
        } else {
          var downloadElement = document.createElement('a')
          var href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.href = href
          downloadElement.download = res.fileName // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href) // 释放掉blob对象
        }
      })
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
  text-align: center;
  font-weight: 700;
}
>>> .el-table .el-table__body tr.el-table__row.current-row td,
.el-table__body tr.current-row > td,
.el-table__body tr.hover-row.current-row > td,
.el-table__body tr.hover-row.el-table__row.current-row > td,
.el-table__body tr.hover-row.el-table__row > td,
.el-table__body tr.hover-row > td {
  background-color: #409eff !important;
  color: white;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
.DivTable {
  width: 99%;
  margin: 0 auto;
  margin-top: 10px;
}
table {
  display: table;
  border-collapse: collapse;
  box-sizing: border-box;
  text-indent: initial;
  white-space: normal;
  line-height: normal;
  font-weight: normal;
  font-size: medium;
  font-style: normal;
  color: -internal-quirk-inherit;
  text-align: start;
  border: 1px solid #bdc8d3;
  font-variant: normal;
}
.DivTable tr {
  min-height: 32px;
  border: 0;
}
.DivTable .EdtTitleBack {
  background-color: #fbfafa;
  text-align: right;
  height: 32px;
  overflow: hidden;
}
.DivTable td {
  min-height: 28px;
  border: 0;
  border: 1px solid #bdc8d3;
}
.DivTable .EdtBack {
  background-color: #fff;
  text-align: left;
  height: 32px;
  overflow: hidden;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
.staffBmTitle {
  margin-top: 1px;
  font-weight: 700;
  text-align: center;
  display: block;
  color: #000;
  font-size: 24px;
  margin-bottom: 10px;
}
.inputnoborder >>> .el-input__inner {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-color: #000;
}
.staffBmTable {
  width: 100%;
  font-size: 14px;
  text-align: center;
  border-color: #000;
}
.staffBmTable tr td {
  padding: 10px;
}
.cell-class {
  border-color: #000 !important;
}
.rspan {
  width: 100px !important;
  text-align: right;
}
.btngroup {
  height: 28px;
  padding: 3px !important;
  width: 98%;
  margin: 2px auto;
  overflow: hidden;
  min-width: 600px;
  text-align: left;
}
.sm_search {
  padding: initial;
  border-top: 1px solid #ddd !important;
  background-color: #f3f3f3;
  width: 100%;
}
.sm_search_group {
  display: flex;
  padding: 5px 0 5px 0;
}
.sm_search_item {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
}
.sm_search_title {
  min-width: 65px;
}
</style>
